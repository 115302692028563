export const financialInstitutions = [
  "Investment Managers",
  "Insurance Companies",
  "Private Equity Firms",
  "Hedge Funds",
  "Family Offices",
  "Direct Lenders",
  "Fund Administrators",
  "Banks"      
]
