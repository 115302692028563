import * as React from "react"
import { Link } from "@reach/router"
import { Typography, Grid, Button } from "@material-ui/core"

import blueSmallTriangleImg from "../../../../media/blue-small-triangle.png"

import { caseStudiesList } from "./data"
import { useStyles } from "./styles"

const title = "Client Success Stories"

const CaseStudies = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Grid container spacing={10} className={classes.listWrapper}>
        {caseStudiesList.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}
            key={`case-study-item-${index}`}
            className={classes.cardItem}
          >
            <div className={classes.cardItemInnerWrapper}>
              <img src={blueSmallTriangleImg} className={classes.cardItemTagImage} />
              <Typography variant="body2" className={classes.cardItemDescription}>
                {item.description}
              </Typography>
              <Button variant="contained" color="primary" href={item.link} className={classes.itemLink}>
                View Case Study
              </Button>
              {/* <Link to={item.link} className={classes.itemLink}>
                <div className={classes.downloadLinkWrapper}>
                  <Typography variant="subtitle2" className={classes.downloadLinkText}>
                    View
                  </Typography>
                </div>
              </Link> */}
            </div>
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default CaseStudies
