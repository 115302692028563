export const caseStudiesList = [
  {
    description: "Insurance Holding Company leverages full suite of Grandview services to achieve their target operating model",
    link: "/case-study/insurance-holding-company",
  },
  {
    description: "Grandview integrates Rivvit with BNY Mellon",
    link: "/case-study/bny-melon-integration",
  },
  {
    description:
      "Grandview integrates with Moody’s Analytics to drive transparency for CLO Managers and Investors",
    link: "/case-study/moodys-analytics-integration",
  },
  {
    description:
      "Global asset manager deploys BISAM B-One for Performance and Attribution",
    link: "/case-study/global-asset-manager",
  },
  {
    description: "Asset manager reduces market data spending by 40%",
    link: "/case-study/data-strategy",
  },
  {
    description:
      "Insurance company deploys Blackrock Aladdin and Eagle and brings investment operations in-house",
    link: "/case-study/system-implementation-and-organization-planning",
  },
  {
    description:
      "Global asset manager migrates to Bloomberg PORT for portfolio risk and performance",
    link: "/case-study/system-implementation",
  },
  {
    description:
      "Investment manager integrates investment and accounting books with market data providers to create a single source of truth",
    link: "/case-study/system-integration",
  },
  {
    description:
      "Direct lender seeks technology roadmap to automate pipeline management, portfolio monitoring, and loan administration",
    link: "/case-study/technology-advisory",
  },
  {
    description:
      "Insurance company outsources data management and reporting using Grandview's Rivvit platform",
    link: "/case-study/rivvit",
  },
  {
    description:
      "Multi-national insurance company enhances portfolio risk and analytics capabilities for portfolio managers",
    link: "/case-study/portfolio-risk-and-analytics",
  },
]
