import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#f0f0f0",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    marginTop: 40,
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listWrapper: {
    marginTop: 40,
  },
  cardItem: {
    width: "100%",
    marginBottom: 20,
    borderRadius: "0 !important",
    boxShadow: "none",
  },
  itemLink: {
    textDecoration: "none",
    color: "#fff",
    marginTop: 30,
    textTransform: "none",
    fontSize: "1.25rem",
    maxWidth: 250,
    margin: "30px auto 0px"
  },
  cardItemInnerWrapper: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    height: "100%",
    minHeight: 150,
    backgroundColor: "#fff",
    padding: 40,
  },
  cardItemTagImage: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "30%",
  },
  cardItemDescription: {
    textAlign: "left",
  },
  downloadLink: {
    textAlign: "center",
    textDecoration: "none",
  },
  downloadLinkWrapper: {
    maxWidth: 360,
    minWidth: 200,
    border: "1px solid black",
    marginTop: 40,
  },
  downloadLinkText: {
    color: "#27aae1",
    textAlign: "center",
  },
}))
