import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: "0 60px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: "2rem",
    margin: "60px 0px 5px"
  },
  listWrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 20,
  },
  item: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemDot: {
    color: "#27aae1",
    margin: 10
  },
  itemName: {
    fontSize: 20,
    textTransform: "uppercase",
    textAlign: "center",
  },
  verticalBar: {
    width: 2,
    height: 40,
    backgroundColor : "#27aae1",
    marginLeft: 10,
  },
}))
