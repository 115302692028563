import * as React from "react"
import { Typography, Grid } from "@material-ui/core"
import StopIcon from "@material-ui/icons/Stop"
import IconButton from "@material-ui/core/IconButton"

import { financialInstitutions } from "./data"
import { useStyles } from "./styles"

const title = "Our clients include a broad range of financial institutions"

const FinancialInstitutions = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.listWrapper}>
          <Grid container>
          {financialInstitutions.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}
              key={`financial-institution-item-${index}`}
              className={classes.item}
            >
                <StopIcon className={classes.itemDot}/>
              <Typography variant="subtitle2" className={classes.itemName}>
                {item}
              </Typography>
            </Grid>
          ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default FinancialInstitutions
