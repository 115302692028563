import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Hero from "../../components/Hero"
import Introduction from "../../components/Introduction"
import FinancialInstitutions from "../../sections/client-work/FinancialInstitutions/"
import CaseStudies from "../../sections/client-work/CaseStudies"
import NextService from "../../sections/services/NextService"

import clientWorkHeroBgUrl from "../../../media/client-work-banner-overlay.png"

const clientWorkHeroTitle = "Client Success Stories"
const clientWorkHeroDescription = ""
const introductionTitle = "Grandview solves complex data and technology challenges to accelerate value"
const introductionPara = "Clients rely on Grandview to advise and execute on complex transformational initiatives that require business and technology expertise. Based on a history of success and reputation for excellence, many of our clients come to us by way of referrals and repeat business. As a trusted advisor, we seek longstanding relationships by always doing what is best for our clients."
const para = [
  introductionPara
]


const ClientWorkPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Client Work" />
    <Hero
      backgroundImage={clientWorkHeroBgUrl}
      title={clientWorkHeroTitle}
      description={clientWorkHeroDescription}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <FinancialInstitutions />
    <CaseStudies />
    <NextService
      text="Next, meet our team"
      link="about-us"
    />
  </Layout>
)

export default ClientWorkPage
